<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-page-inner title="电子账薄管理">
        <el-col :span="24" style="padding-left: 10px;padding-right: 90px;">
          <div class="bank-account yn-page">
            <div class="bank-cont">
              <div v-for="(item, index) in accountList" :key="index" class="bank-card active1">
                <div class="bank-header">
                  <div class="bank-name">
                    <div>{{ utils.isEffectiveCommon(item.eaccountName) }}</div>
                  </div>
                  <div class="default">
                    {{ utils.statusFormat(item.userType, 'accountUserType') }}
                  </div>
                </div>
                <div class="bank-section">
                  <div>
                    <span>{{ utils.formatBankNo(item.eaccountNumber) }}</span>
                  </div>
                </div>
                <div class="bank-footer">
                  <span v-if="item.signStauts !== '20'" class="span-status">状态：开户中</span>
                  <el-link v-if="item.signStauts === '20'" type="danger" class="el-link-edit" :underline="false" @click="dataProcessing(item, 'detail')">
                    详情
                  </el-link>
                  <span v-if="item.signStauts === '20' && item.bankInfo">
                    <el-link v-if="item.bankInfo.delFlag === 0 && item.bankInfo.bingdingBankStatus === '30'" type="danger" class="el-link-edit" :underline="false" @click="relieve(item)">
                      解绑
                    </el-link>
                    <el-link v-if="item.bankInfo.delFlag === 1" type="danger" class="el-link-edit" :underline="false" @click="binding(item)">
                      绑卡
                    </el-link>
                    <el-link v-if="[0, 1].includes(item.bankInfo.delFlag) && ['15', '18'].includes(item.bankInfo.bingdingBankStatus)" type="danger" class="el-link-edit" :underline="false" @click="binding(item)">
                      绑卡
                    </el-link>
                  </span>
                  <el-link v-if="item.signStauts !== '20'" type="danger" class="el-link-edit" :underline="false" @click="dataProcessing(item, 'edit')">
                    编辑
                  </el-link>
                  <el-link v-if="item.signStauts !== '20'" type="danger" class="el-link-delete" :underline="false" @click="dataProcessing(item, 'del')">
                    删除
                  </el-link>
                </div>
              </div>
              <div class="add-bank theme-color-border theme-color-text" @click="addAccountInfo">
                <span class="add-image el-icon-plus" />
                <div>添加银行账户</div>
              </div>
            </div>
          </div>
        </el-col>
      </ics-page-inner>
    </div>
    <ics-dialog-inner :loading="loading.submit" :visible.sync="dialog.determine.visible" :title="dialog.determine.title" submit-title="确定" cancel-title="取消" class="form-dialog" width="40%" @submit="determineSubmit" @close="closeDetermine">
      <ics-page-inner title="" :show-header="true">
        <el-col :span="24">
          <p>
            <input style="vertical-align: middle;margin-left: 15%;margin-top: -3px;" :disabled="!userService" type="checkbox" :checked="checked">
            我已阅读并同意《<a class="text-btn" href="javascript:" @click="preview(1)">油信云金平台会员服务协议</a>》
          </p>
          <p>
            <input style="vertical-align: middle;margin-left: 15%;margin-top: -3px;" :disabled="!third" type="checkbox" :checked="thirdChecked">
            我已阅读并同意《<a class="text-btn" href="javascript:" @click="preview(2)">账服通用户签约收款协议</a>》
          </p>
        </el-col>
      </ics-page-inner>
    </ics-dialog-inner>
    <ics-dialog-inner :loading="loading.submit" :visible.sync="dialog.preview.visible" :title="dialog.preview.title" :show-cancel="false" :show-submit="false" class="form-dialog" width="60%" @close="closePreview">
      <ics-page-inner title="" :show-header="true">
        <el-col :span="24">
          <div class="form-inner" style="height: 450px;overflow:auto;overflow-x:hidden;">
            <div style="position: absolute; left: 3%;" class="el-carousel__container">
              <button v-show="Number(pdfPage) !== 1" style="left: 10px; margin-top: 50%; margin-left: 50%;" type="button" size="small" class="el-carousel__arrow  " @click.stop="previousPage">
                <i class="el-icon-arrow-left" />
              </button>
            </div>
            <div style="position: absolute; right: 10%;" class="el-carousel__container">
              <button v-show="Number(pdfPage) !== Number(pageCount)" style="margin-top: 50%; margin-left: 50%;" type="button" size="small" class="el-carousel__arrow  " @click.stop="nextPage">
                <i class="el-icon-arrow-right" />
              </button>
            </div>
            <pdf :src="url" :page="pdfPage" style="display: inline-block; width: 100%;" @num-pages="pageCount = $event" @page-loaded="pdfPage = $event" />
          </div>
        </el-col>
        <el-col :span="24" style="padding: 0px 0px 20px;margin:0 auto; text-align: center; margin-top: 20px;">
          <el-button :disabled="disabledTime" class="add-btn" type="primary" @click="readPreview">
            <span v-show="disabledTime">{{ time }}S</span>我已阅读并知晓
          </el-button>
        </el-col>
      </ics-page-inner>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
import pdf from 'vue-pdf'
export default {
  components: { IcsDialogInner, pdf },
  mixins: [routeEnterMixin],
  data() {
    return {
      accountList: [],
      dialog: {
        determine: {
          visible: false,
          title: ''
        },
        preview: {
          visible: false,
          title: ''
        }
      },
      userService: false,
      pdfPage: 1,
      pageCount: 0,
      url: '',
      time: 5,
      currentNum: undefined,
      disabledTime: true,
      checked: false,
      third: false,
      thirdChecked: false,
      loading: {
        submit: false
      }
    }
  },
  watch: {},
  created () {
    this.getAccountList()
  },
  methods: {
    getAccountList () {
      this.api.system.account.getAccountList().then(result => {
        this.accountList = result.data.data || []
      }).finally(() => {
      })
    },
    addAccountInfo () {
      this.dialog.determine.visible = true
      this.dialog.determine.title = '协议确认'
    },
    determineSubmit () {
      if (this.userService && this.third) {
        this.$router.push({ name: 'accountEdit', params: { editMode: 'add' }})
      } else {
        this.$message.warning('请阅读并勾选相关协议')
        return false
      }
    },
    closeDetermine () {
      this.checked = false
      this.userService = false
      this.thirdChecked = false
      this.third = false
    },
    preview (type) {
      this.currentNum = type
      this.dialog.preview.visible = true
      this.dialog.preview.title = '协议预览'
      if (!this.userService || !this.third) {
        this.disabledTime = true
        this.timer = setInterval(() => {
          if (this.time === 0) {
            clearInterval(this.timer)
            this.time = 5
            this.disabledTime = false
          } else {
            this.time--
          }
        }, 1000)
      } else {
        this.disabledTime = false
      }
      if (type === 1) {
        this.url = '/template/油信云金平台会员服务协议.pdf'
      } else {
        this.url = '/template/账服通用户签约收款协议.pdf'
      }
    },
    readPreview () {
      this.dialog.preview.visible = false
      if (this.currentNum === 1) {
        this.checked = true
        this.userService = true
      } else {
        this.thirdChecked = true
        this.third = true
      }
    },
    closePreview () {
      this.dialog.preview.visible = false
      this.time = 5
      this.pdfPage = 1
      this.pageCount = 0
      clearInterval(this.timer)
      this.disabledTime = false
    },
    previousPage () {
      var p = this.pdfPage
      p = p>1?p-1:this.pageCount
      this.pdfPage = p
    },
    nextPage () {
      var p = this.pdfPage
      p = p<this.pageCount?p+1:1
      console.log('p', p)
      this.pdfPage = p
    },
    dataProcessing (row, type) {
      if (type === 'del') {
        this.api.system.account.delAccount(row.userId).then(result => {
          if (result.data.success === true) {
            this.$message.success('操作成功')
            this.getAccountList()
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
        })
      } else if (type === 'edit') {
        if (row.eaccountStatus === '40') {
          this.$router.push({ name: 'accountEdit', params: { editMode: 'edit' }, query: { userId: row.userId } })
          return false
        }
        console.log(row.jumpbandingCardFalg)
        if (row.jumpbandingCardFalg) {
          this.$router.push({ name: 'waitFor', query: { userId: row.userId, firmId: row.firmId } })
        } else {
          if (row.collectionStatus) {
            if (row.collectionStatus === '30') {
              this.$router.push({ name: 'accountEdit', params: { editMode: 'edit' }, query: { userId: row.userId } })
            } else if (row.collectionStatus && row.collectionStatus === '20') {
              if (row.bankInfo) {
                if (['15', '17', '18'].includes(row.bankInfo.bingdingBankStatus)) {
                  this.$router.push({ name: 'verificationOrPay', query: { userId: row.userId, firmId: row.firmId } })
                } else {
                  if (row.bankInfo.bingdingBankStatus === '30') {
                    this.$router.push({ name: 'waitFor', query: { userId: row.userId, firmId: row.firmId } })
                  } else {
                    this.$router.push({ name: 'bindingBankEdit', query: { userId: row.userId, firmId: row.firmId } })
                  }
                }
              } else {
                this.$router.push({ name: 'bindingBankEdit', query: { userId: row.userId, firmId: row.firmId } })
              }
            } else {
              this.$router.push({ name: 'bindingBankEdit', query: { userId: row.userId, firmId: row.firmId } })
            }
          } else {
            this.$router.push({ name: 'accountEdit', params: { editMode: 'edit' }, query: { userId: row.userId } })
          }
        }
      } else if (type === 'detail') {
        this.$router.push({ name: 'accountDetail', query: { userId: row.userId, firmId: row.firmId, eaccountNumber: row.eaccountNumber } })
      } else {
        return false
      }
    },
    relieve (row) {
      this.$confirm('此操作将解除该账户下的银行账户绑定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        callback: () => {},
        beforeClose: (action, ctx, close) => {
          if (action !== 'confirm') {
            close()
            return
          }
          ctx.confirmButtonLoading = true
          this.api.system.account.relieveEJz4611(row.userId, '20').then(result => {
            const ejz4611 = JSON.parse(result.data.data)
            if (ejz4611.code === '2000' && ['15', '17', '18', '30'].includes(ejz4611.status)) {
              this.$message.success(ejz4611.responseMessage ? ejz4611.responseMessage : ejz4611.message)
              this.getAccountList()
              close()
            } else {
              this.$message.error(ejz4611.responseMessage ? ejz4611.responseMessage : ejz4611.message)
              close()
            }
          }).finally(() => {
            ctx.confirmButtonLoading = false;
          })
        }
      }).finally(() => {})
    },
    binding (row) {
      if (['15', '18'].includes(row.bankInfo.bingdingBankStatus)) {
        this.$router.push({ name: 'verificationOrPay1', query: { userId: row.userId, firmId: row.firmId } })
      } else {
        this.$router.push({ name: 'bindingBank1Edit', query: { userId: row.userId, firmId: row.firmId } })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.normal .page-title {
  width: calc(100%);
  height: 48px;
}
[class*=" el-icon-"], [class^=el-icon-] {
  font-family: element-icons!important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bank-status {
  width: 70px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
</style>
